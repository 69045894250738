import React from "react"
import { Container } from "react-bootstrap"
import { CompleteTermsSection } from "./CompleteTerms.styles"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"

const CompleteTerms = () => {
  return (
    <CompleteTermsSection className="py-5 px-4 px-lg-0">
      <Container>
        <h2 className="h1 my-5 text-uppercase secondary-blue-2-text">
          TERMS AND CONDITIONS OF WEBSITE USE
        </h2>
        <p className="mb-5">
          This website is owned and operated by APEXX Fintech Limited (“APEXX”,
          “we” or “us), a company registered in the United Kingdom, under
          company number 10131902, with its registered address at 5a Underwood
          Street, London, England, N1 7LY.
          <br />
          <br />
          These terms apply to your use of this website and any other websites
          we operate and make available from time to time (“Website”), including
          your use of any materials or information which may be made available
          to you from a Website. It is important that you read these terms as by
          accessing and using the Website, you will be deemed to have agreed to
          be bound by these terms. If you do not agree to be bound by these
          terms, please immediately refrain from using the Website.
          <br />
          <br />
          The Website is not intended to be used or accessed by anyone who is
          under the age of 18. By using the Website and agreeing to these terms,
          you represent and warrant that you are at least 18 years of age.
        </p>
        <h3 className="my-5 text-uppercase primary-blue-text">
          RIGHTS AND USE OF OUR WEBSITE
        </h3>
        <p className="mb-5">
          All content (which includes, without limitation any and all text,
          graphics, images, audio, video, software, data compilations, page
          layout, underlying code and software and any other form of information
          that appears on or forms part of the Website, together “Content”)
          included on the Website is the property of APEXX and our licensors.
          You acknowledge that such Content is protected by intellectual
          property rights and your use is strictly restricted as set out in
          these terms.
          <br />
          <br />
          The trade marks, graphics and logos used on or in connection with this
          Website belong to APEXX and our licensors. You are granted no right to
          use such marks and any unauthorised use is strictly prohibited.
          <br />
          <br />
          You may access and use the Content on the Website for your own
          personal reference and/or as strictly required in order to use
          products or services provided to you by APEXX under an agreement. You
          may print off one copy, and may download extracts, of any page(s) from
          our Website for your personal use and you may draw the attention of
          others within your organisation to content contained on the Website.
          <br />
          <br />
          <b>You must not (and shall not permit others to):</b>
        </p>
        <ul>
          <li>
            <p>
                Reproduce, modify, copy, create derivative works from,
                distribute or use for commercial purposes any Content without
                the written permission of APEXX;
            </p>{" "}
          </li>
          <li>
            <p>
              Remove or alter any proprietary notices from or embedded within
              the Content;
            </p>{" "}
          </li>
          <li>
            <p>
              Use the Website in any way which causes, or may cause, damage to
              the Website or interferes with any other person's use or enjoyment
              of the Website;
            </p>{" "}
          </li>
          <li>
            <p>
              Use the Website in any way which is harmful, unlawful, illegal,
              abusive, harassing, threatening or otherwise objectionable or in
              breach of any applicable law, regulation, governmental order;
              <br />
              or
            </p>{" "}
          </li>
          <li>
            <p>breach or attempt to breach the security of this Website.</p>{" "}
          </li>
        </ul>
        <p>
          All rights not expressly granted in these terms are reserved by APEXX
          and our licensors.
        </p>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            LINKS TO OTHER WEBSITES
          </h3>
          <p className="mb-5">
            The Website may contain links to third-party websites. Unless
            expressly stated, these sites are not under APEXX control and we do
            not accept any responsibility for these websites nor for any content
            available from those sites. APEXX disclaims liability for any and
            all forms of loss or damage arising from using the third-party
            websites.
            <br />
            <br />
            The inclusion of a link to another site on the Website does not
            imply any endorsement of the sites or of those in control of the
            sites.
            <br />
            <br />
            Anyone wishing to place a link to one or more of our Websites on
            other sites may do so with our prior written permission and,
            notwithstanding that we may have granted permission, may only do so
            in a way that is fair and legal and does not damage our reputation
            or take advantage of it.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            PRIVACY AND COOKIES
          </h3>
          <p className="mb-5">
            Any information you submit through this Website will be used in
            accordance with our{" "}
            <Link
              className="secondary-blue-2-text"
              to={LINKS.privacyPolicy}
              target="_blank"
            >
              Privacy Policy
            </Link>
            . We also use cookies when you visit our Website. For more
            information on the use of cookies, please review our{" "}
            <Link
              className="secondary-blue-2-text"
              to={LINKS.cookiesPolicy}
              target="_blank"
            >
              Cookies Policy
            </Link>
            . Our{" "}
            <Link
              className="secondary-blue-2-text"
              to={LINKS.privacyPolicy}
              target="_blank"
            >
              Privacy and Cookies Policies{" "}
            </Link>
            are incorporated into these terms by this reference.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">DISCLAIMER</h3>
          <p className="mb-5">
            The Content and any information, tools, services or other resources
            that APEXX makes available through the Website (“Resources”) are
            provided "as is" and on an "as available" basis without warranty of
            any kind. APEXX expressly disclaims all warranties whether express,
            implied or statutory, including, but not limited to, the implied
            warranties of fitness for a particular purpose, accuracy of
            information, compatibility and satisfactory quality. Without
            limiting the generality of the foregoing, we do not warrant that the
            Resources will meet your requirements or that your use of the
            Resources will be uninterrupted or error-free. We are not
            responsible for any delays, delivery failures, or any other loss or
            damage resulting from the transfer of data over communications
            networks and facilities, including the internet, and you acknowledge
            that the Resources may be subject to limitations, delays and other
            problems inherent in the use of such communications facilities.
            <br />
            <br />
            This Website is provided for general information only. You
            acknowledge and agree that we will not be liable to you or any
            person for any claims, losses or damages (whether direct, indirect
            or consequential) of any kind that may arise from your use of this
            Website or any of the Resources made available through the Website
            nor for any disruption or non-availability of the Website, except to
            the extent that such liability cannot be excluded or limited by
            applicable laws.
            <br />
            <br />
            APEXX has used reasonable endeavours to ensure that the Website is
            secure and free of viruses and malware. However, we give no warranty
            or guarantee in that regard and you take responsibility for your own
            security, including without limitation the security of your devices.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            ACCESS TO THE WEBSITE
          </h3>
          <p className="mb-5">
            APEXX reserves the right to alter, suspend or discontinue any part
            (or the whole of) the Website including without limitation any
            products and/or services made available through the Website.
            <br />
            <br />
            Unless otherwise stated, these terms shall continue to apply to any
            modified version of the Website.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">
            SUSPENSION AND TERMINATION
          </h3>
          <p className="mb-5">
            We will determine, in our absolute sole discretion, whether there
            has been any breach of these terms through your use of the Website.
            In the event of any actual or suspected breach, we may take such
            action as we deem appropriate including without limitation
            restricting or withdrawing your access to the Website without
            notice.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">GENERAL</h3>
          <p className="mb-5">
            You may not transfer any of your rights under these terms to any
            other person. We may transfer our rights under these terms and
            conditions where we reasonably believe your rights will not be
            affected.
            <br />
            <br />
            These terms may be varied by us from time to time. Such revised
            terms will apply to the Website from the date of publication. You
            should check this page on occasion to ensure you are familiar with
            the then current version.
            <br />
            <br />
            These terms and conditions together with our{" "}
            <Link
              className="secondary-blue-2-text"
              to={LINKS.privacyPolicy}
              target="_blank"
            >
              Privacy Policy
            </Link>{" "}
            and Cookies Policy constitute the whole agreement between APEXX and
            you relating to its subject matter and supersedes and extinguishes
            all prior discussions, arrangements or agreements that might have
            taken place relating to its subject matter.
            <br />
            <br />
            These terms do not give rise to any rights under the Contracts
            (Rights of Third Parties) Act 1999, which shall not apply, and no
            third party will have any right to enforce or rely on any provision
            of these terms.
            <br />
            <br />
            If any of these terms are found to be invalid, illegal or
            unenforceable, that provision or part-provision will, to the extent
            required, be deemed to be deleted, and the validity and
            enforceability of the other provisions of these terms will not be
            affected.
            <br />
            <br />
            Our delay or failure to exercise or enforce any right or remedy will
            not be deemed a waiver of that, or any other, right or remedy.
            <br />
            <br />
            These terms will be governed by and construed in accordance with
            English law. All disputes arising under these terms and conditions
            will be subject to the exclusive jurisdiction of the English courts.
          </p>
        </section>
        <section>
          <h3 className="my-5 text-uppercase primary-blue-text">CONTACT US</h3>
          <p className="mb-5">
            If you require any further information, please contact us by email
            to:
            <br />
            <br />
            <span className="secondary-blue-2-text">legal@apexx.global,</span>
            <br />
            <br />
            or by post to:
            <br />
            <br />
            <span className="secondary-blue-2-text">
              APEXX Global, First Floor, 69 Turnmill Street, Farringdon, London, EC1M 5RR, United Kingdom.
            </span>
          </p>
        </section>
      </Container>
    </CompleteTermsSection>
  )
}

export default CompleteTerms
