import React from "react"
import Layout from "../components/Global/Layout/Layout"
import CovidUpdate from "../components/Global/Common/CovidUpdate/CovidUpdate"
import DownloadSection from "../components/TermsAndCondition/DownloadSection/DownloadSection"
import CompleteTerms from "../components/TermsAndCondition/CompleteTerms/CompleteTerms"
import SEO from "../components/Global/SEO/seo"

const TermsAndConditionsPage = () => (
  <Layout>
    <SEO page="terms-conditions" />
    <CovidUpdate/>
    <DownloadSection/>
    <CompleteTerms/>
  </Layout>
)

export default TermsAndConditionsPage
