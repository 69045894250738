import React from "react"
import { Container } from "react-bootstrap"
import { CovidUpdateSectionStyles } from "./CovidUpdate.styles"

const CovidUpdate = () => {
  return (
    <CovidUpdateSectionStyles className="py-3 px-3 px-lg-0">
      <Container>
        <h6 className="primary-blue-text pb-2">An update regarding COVID-19</h6>
        <p className="small">
          With the COVID-19 situation continuing to develop globally, we’ve
          introduced a work-from-home policy for our team. We see this as a
          responsible choice to protect our colleagues, families and the wider
          community while doing our bit to flatten the curve of infection on a
          social level.
        </p>
        <p className="small">
          Please note that due to the continuous situation all interviews will
          be conducted via video call. We feel this is necessary in safeguarding
          your safety and that of our team. On that same note, please keep in
          mind that we have to be flexible when it comes to potential starting-
          and onboarding dates.
        </p>
      </Container>
    </CovidUpdateSectionStyles>
  )
}

export default CovidUpdate
